import { grayscale, orange, white } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'
import styled from 'styled-components'

export const Accordeon = styled.div`
  max-height: 65px;
  padding: 15px 15px;
  transition: max-height 0.25s;
  overflow: hidden;
  border: 2px solid ${grayscale[200]};
  border-radius: 8px;
  margin: 10px 0;

  &.active {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
    overflow: visible;
  }
`

export const AccordeonTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  div {
    cursor: pointer;
    background-color: #fff2e7;
    width: 32px;
    height: 32px;
    border-radius: ${rounded['10']};

    &.arrow-active {
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }
`

export const AccordeonContent = styled.div`
  max-width: 600px;
`

export const TooltipWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

export const BottomSheet = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${white};
  border-radius: 8px 8px 0 0;
  z-index: 100;
  padding: 0 24px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
`

export const Handler = styled.div`
  border-radius: 8px;
  width: 48px;
  height: 4px;
  background: ${grayscale[200]};
  margin: auto;
  margin-top: 12px;
  margin-bottom: 24px;
`

export const Title = styled.h3`
  font-family: "Sora", Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${grayscale[500]};
  margin-bottom: 16px;
`

export const Description = styled.span`
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[400]};
  margin-bottom: 24px;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${orange.extra};
  border-radius: 8px;
  width: 100%;
  padding: 16px 0;
  border: none;
  height: 48px;
  margin-bottom: 24px;
  color: ${white};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

export const DarkBG = styled.div`
 background-color: rgba(0,0,0,.4);
  opacity: .8;
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`
